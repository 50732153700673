// app/javascript/animations/initAccordion.js
import { gsap } from "gsap";

export const initAccordion = () => {
    const headings = document.querySelectorAll("h3");

    headings.forEach((heading) => {
        // Prevent attaching multiple event listeners
        if (heading._toggleAccordion) return;

        const icon = heading.querySelector(".heading-icon");
        if (!icon) return;

        const contentWrapper = heading.nextElementSibling;
        if (!contentWrapper || !contentWrapper.classList.contains("accordion-content")) return;

        heading.setAttribute("role", "button");
        heading.setAttribute("aria-expanded", "false");
        contentWrapper.setAttribute("role", "region");
        contentWrapper.setAttribute("aria-hidden", "true");

        const toggleAccordion = () => {
            const isOpen = heading.classList.contains("open");

            if (isOpen) {
                gsap.to(contentWrapper, {
                    height: 0,
                    duration: 0.5,
                    ease: "power2.inOut",
                });
                heading.classList.remove("open");
                gsap.to(icon, { rotation: 0, duration: 0.5 });
                heading.setAttribute("aria-expanded", "false");
                contentWrapper.setAttribute("aria-hidden", "true");
            } else {
                const fullHeight = contentWrapper.scrollHeight;
                gsap.to(contentWrapper, {
                    height: fullHeight,
                    duration: 0.5,
                    ease: "power2.inOut",
                    onComplete: () => {
                        contentWrapper.style.height = "auto";
                    },
                });
                heading.classList.add("open");
                gsap.to(icon, { rotation: 90, duration: 0.5 });
                heading.setAttribute("aria-expanded", "true");
                contentWrapper.setAttribute("aria-hidden", "false");
            }
        };

        heading.addEventListener("click", toggleAccordion);

        heading._toggleAccordion = toggleAccordion;
    });
};
