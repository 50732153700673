// app/javascript/entrypoints/application.js
import "../../assets/stylesheets/application.scss";

import { Application } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

import ProjectsController from "../controllers/projects_controller";
import MainController from "../controllers/main_controller";
import NavController from "../controllers/nav_controller";
import BlogController from "../controllers/blog_controller";

import {
  runAnimations,
  initOverlineAnimations,
  initGlitchAnimations,
  initAccordion,
} from "../animations";

import { gsap } from "gsap";

const application = Application.start();

application.register("projects", ProjectsController);
application.register("main", MainController);
application.register("nav", NavController);
application.register("blog", BlogController);

const cleanupAnimations = () => {
  const h2Wrappers = document.querySelectorAll("h2 .h2-wrapper");
  h2Wrappers.forEach((wrapper) => {
    const h2 = wrapper.parentElement;
    if (h2) {
      h2.innerHTML = wrapper.querySelector(".h2-text").innerHTML;
    }
  });

  const glitchOverlays = document.querySelectorAll(".glitch-overlay");
  glitchOverlays.forEach((overlay) => overlay.remove());

  const accordions = document.querySelectorAll(".accordion-content");
  accordions.forEach((content) => {
    content.style.height = "0";
    content.classList.remove("open");
  });

  const accordionHeadings = document.querySelectorAll("h3");
  accordionHeadings.forEach((heading) => {
    heading.classList.remove("open");
    const icon = heading.querySelector(".heading-icon");
    if (icon) {
      gsap.set(icon, { rotation: 0 });
    }
    heading.setAttribute("aria-expanded", "false");
    const content = heading.nextElementSibling;
    if (content && content.classList.contains("accordion-content")) {
      content.setAttribute("aria-hidden", "true");
    }
  });
};

document.addEventListener("turbo:before-cache", () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.disconnect === "function") {
      controller.disconnect();
    }
  });

  cleanupAnimations();
});

document.addEventListener("turbo:load", () => {
  gsap.from(document.body, { opacity: 0, duration: 0.5 });

  runAnimations();
  initGlitchAnimations();
  initOverlineAnimations();
  initAccordion();

  const videoContainers = document.querySelectorAll(".video-container video");

  videoContainers.forEach((video) => {
    video.setAttribute("controls", "false");
    video.removeAttribute("controls");
    video.setAttribute("playsinline", "true");
    video.style.pointerEvents = "none";

    video.addEventListener("webkitbeginfullscreen", (event) => {
      event.preventDefault();
      video.webkitExitFullscreen();
    });

    video.addEventListener("fullscreenchange", (event) => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    });
  });
});
