// app/javascript/controllers/nav_controller.js
import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

export default class extends Controller {
    static targets = ["menuToggle", "menu", "line1", "line2", "line3", "fixedTop"];

    initialize() {
        this.toggle = this.toggle.bind(this);
        this.closeMenuOnClickOutsideLi = this.closeMenuOnClickOutsideLi.bind(this);
        this.isOpen = false;
    }

    connect() {
        // Ensure the menu is closed on connect
        this.isOpen = false;
        this.menuTarget.classList.remove("open");
        this.fixedTopTarget.classList.remove("no-blur");

        // Reset animation to initial state
        if (this.menuIconAnimation) {
            this.menuIconAnimation.progress(0).pause();
        }

        this.menuToggleTarget.addEventListener("click", this.toggle);

        this.menuIconAnimation = gsap.timeline({ paused: true });

        this.menuIconAnimation.to(
            this.line1Target,
            {
                duration: 0.2,
                y: 8,
                rotation: 45,
                transformOrigin: "center center",
            },
            0
        );

        this.menuIconAnimation.to(
            this.line2Target,
            {
                duration: 0.2,
                opacity: 0,
            },
            0
        );

        this.menuIconAnimation.to(
            this.line3Target,
            {
                duration: 0.2,
                y: -8,
                rotation: -45,
                transformOrigin: "center center",
            },
            0
        );

        setTimeout(() => {
            this.menuTarget.classList.add("transition");
        }, 0);
    }

    toggle(event) {
        event.stopPropagation();
        this.menuTarget.classList.toggle("open");

        if (this.isOpen) {
            this.menuIconAnimation.reverse();
            document.removeEventListener("click", this.closeMenuOnClickOutsideLi);
            this.fixedTopTarget.classList.remove("no-blur");
        } else {
            this.menuIconAnimation.play();

            setTimeout(() => {
                document.addEventListener("click", this.closeMenuOnClickOutsideLi);
            }, 0);
            this.fixedTopTarget.classList.add("no-blur");
        }
        this.isOpen = !this.isOpen;
    }

    closeMenuOnClickOutsideLi(event) {
        if (!event.target.closest("li")) {
            this.toggle(event);
        }
    }

    disconnect() {
        this.menuToggleTarget.removeEventListener("click", this.toggle);
        document.removeEventListener("click", this.closeMenuOnClickOutsideLi);
        if (this.menuIconAnimation) {
            this.menuIconAnimation.kill();
            this.menuIconAnimation = null;
        }
        // Ensure the menu is closed when disconnected
        this.menuTarget.classList.remove("open");
        this.fixedTopTarget.classList.remove("no-blur");
        this.isOpen = false;
    }
}
