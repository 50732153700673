// app/javascript/controllers/main_controller.js
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { gsap } from "gsap";

export default class extends Controller {
    static targets = ["content"];

    connect() {
        this.createTransitionOverlay();
        this.setupTurboListeners();
        this.fadeInContent();
    }

    disconnect() {
        // Remove the transition overlay if it exists
        if (this.transitionOverlay) {
            this.transitionOverlay.remove();
            this.transitionOverlay = null;
        }

        // Clean up any ongoing animations or styles
        this.contentTargets.forEach((content) => {
            content.classList.remove("visible");
            gsap.killTweensOf(content);
        });

        // Remove Turbo event listeners
        document.removeEventListener("turbo:visit", this.onTurboVisitBound);
        document.removeEventListener("turbo:load", this.onTurboLoadBound);
    }

    /**
     * Create and append the transition overlay to the body
     */
    createTransitionOverlay() {
        // Create the overlay element
        this.transitionOverlay = document.createElement("div");
        this.transitionOverlay.id = "transition-overlay";

        // Apply styles directly via JavaScript
        Object.assign(this.transitionOverlay.style, {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "linear-gradient(135deg, #0a84ff, #ff5e5e)", // Customize gradient as needed
            opacity: "0",
            zIndex: "9999",
            pointerEvents: "none",
            transform: "scaleY(0)",
            transformOrigin: "top",
            transition: "opacity 0.8s ease, transform 0.8s ease", // Fallback if GSAP fails
        });

        // Append the overlay to the body
        document.body.appendChild(this.transitionOverlay);
    }

    /**
     * Setup Turbo event listeners for transitions
     */
    setupTurboListeners() {
        // Bind the event handlers to ensure proper context
        this.onTurboVisitBound = this.onTurboVisit.bind(this);
        this.onTurboLoadBound = this.onTurboLoad.bind(this);

        // Listen to Turbo's visit and load events
        document.addEventListener("turbo:visit", this.onTurboVisitBound);
        document.addEventListener("turbo:load", this.onTurboLoadBound);
    }

    /**
     * Handle the turbo:visit event
     */
    onTurboVisit() {
        if (!this.transitionOverlay) return;

        // Enable pointer events to block interactions during transition
        this.transitionOverlay.style.pointerEvents = "auto";

        // Animate the overlay in: scaleY from 0 to 1 and fade in
        gsap.to(this.transitionOverlay, {
            duration: 0.8,
            opacity: 1,
            scaleY: 1,
            ease: "power2.inOut",
            onStart: () => {
                // Optionally, pause any ongoing animations on the current page
            },
            onComplete: () => {
                // Optionally, perform actions after the entrance animation completes
            },
        });
    }

    /**
     * Handle the turbo:load event
     */
    onTurboLoad() {
        if (!this.transitionOverlay) return;

        // Animate the overlay out: scaleY from 1 to 0 and fade out
        gsap.to(this.transitionOverlay, {
            duration: 0.8,
            opacity: 0,
            scaleY: 0,
            ease: "power2.inOut",
            onComplete: () => {
                // Disable pointer events after transition
                this.transitionOverlay.style.pointerEvents = "none";
            },
        });

        // Initialize other animations after the transition
        this.initializePageAnimations();
    }

    /**
     * Fade in the page content
     */
    fadeInContent() {
        this.contentTargets.forEach((content) => {
            // Initially set content to be transparent and slightly moved down
            gsap.set(content, { opacity: 0, y: 20 });

            // Animate the content to fade in and move up to its original position
            gsap.to(content, {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power2.out",
                delay: 0.3,
            });
        });
    }

    /**
     * Initialize other page-specific animations
     */
    initializePageAnimations() {
        // Assuming these functions are globally accessible or imported
        if (typeof runAnimations === "function") runAnimations();
        if (typeof initGlitchAnimations === "function") initGlitchAnimations();
        if (typeof initOverlineAnimations === "function") initOverlineAnimations();
        if (typeof initAccordion === "function") initAccordion();

        // Handle video elements
        const videoContainers = document.querySelectorAll(".video-container video");

        videoContainers.forEach((video) => {
            // Disable fullscreen and controls
            video.setAttribute("controls", "false");
            video.removeAttribute("controls");
            video.setAttribute("playsinline", "true");
            video.style.pointerEvents = "none";

            video.addEventListener("webkitbeginfullscreen", (event) => {
                event.preventDefault();
                video.webkitExitFullscreen();
            });

            video.addEventListener("fullscreenchange", (event) => {
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
            });
        });
    }
}
