// app/javascript/animations/initOverlineAnimations.js
import { gsap } from "gsap";

export const initOverlineAnimations = () => {
    const h2Elements = document.querySelectorAll("h2");

    h2Elements.forEach((h2) => {
        // Prevent double wrapping
        if (h2.querySelector(".h2-wrapper")) return;

        const wrapper = document.createElement("div");
        wrapper.classList.add("h2-wrapper");
        wrapper.style.display = "inline-block";
        wrapper.style.position = "relative";
        wrapper.style.overflow = "hidden";

        const textSpan = document.createElement("span");
        textSpan.classList.add("h2-text");
        textSpan.innerHTML = h2.innerHTML;

        wrapper.appendChild(textSpan);

        const overlay = document.createElement("div");
        overlay.classList.add("h2-overlay");
        wrapper.appendChild(overlay);

        h2.innerHTML = "";
        h2.appendChild(wrapper);
    });

    const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 1,
    };

    const observerCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const h2 = entry.target;
                const overlay = h2.querySelector(".h2-overlay");

                if (overlay) {
                    gsap.to(overlay, {
                        delay: 0.5,
                        duration: 1,
                        x: "100%",
                        ease: "power2.out",
                        onComplete: () => {
                            gsap.to(overlay, {
                                delay: 2,
                                onComplete: () => overlay.remove(),
                            });
                        },
                    });
                }
                observer.unobserve(h2);
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    h2Elements.forEach((h2) => {
        observer.observe(h2);
    });
};
