// app/javascript/animations/initGlitchAnimations.js
import { gsap } from "gsap";

export const initGlitchAnimations = () => {
    const config = {
        squareSize: 10,
        coveragePercentage: 0.04,
        overlayDisplayDuration: 0.5,
        overlayFadeDuration: 0.1,
        fadeOutEasing: "power2.in",
        observerThreshold: 0.1,
        pixelColors: [
            "rgb(54,77,95)",
            "rgb(61,77,95)",
            "rgb(24,45,56)",
            "rgba(255, 255, 0, 0.5)",
            "rgb(146,162,180)",
            "rgba(0, 255, 255, 0.5)",
        ],
        pixelOffset: 4,
    };

    const elements = document.querySelectorAll("li, code, pre");

    const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: config.observerThreshold,
    };

    const observerCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const el = entry.target;

                if (window.getComputedStyle(el).position === "static") {
                    el.style.position = "relative";
                }

                el.classList.add("glitching");

                const overlay = createGlitchOverlay(el, config);
                el.appendChild(overlay);

                gsap.to(overlay, {
                    delay: config.overlayDisplayDuration,
                    duration: config.overlayFadeDuration,
                    opacity: 0,
                    ease: config.fadeOutEasing,
                    onComplete: () => {
                        overlay.remove();
                        el.classList.remove("glitching");
                    },
                });

                observer.unobserve(el);
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elements.forEach((element) => {
        observer.observe(element);
    });

    function createGlitchOverlay(element, config) {
        const overlay = document.createElement("div");
        overlay.classList.add("glitch-overlay");
        overlay.style.position = "absolute";
        overlay.style.top = `-${config.pixelOffset}px`;
        overlay.style.left = `-${config.pixelOffset}px`;
        overlay.style.width = `calc(100% + ${config.pixelOffset * 2}px)`;
        overlay.style.height = `calc(100% + ${config.pixelOffset * 2}px)`;
        overlay.style.pointerEvents = "none";
        overlay.style.zIndex = "1";
        overlay.style.overflow = "hidden";

        const squareSize = config.squareSize;
        const coveragePercentage = config.coveragePercentage;
        const pixelColors = config.pixelColors;
        const elementWidth = element.offsetWidth + config.pixelOffset * 2;
        const elementHeight = element.offsetHeight + config.pixelOffset * 2;
        const columns = Math.ceil(elementWidth / squareSize);
        const rows = Math.ceil(elementHeight / squareSize);
        const totalSquares = columns * rows;
        const numSquaresToDisplay = Math.floor(totalSquares * coveragePercentage);
        const pCluster = 0.8;
        const clusterWidthInSquares = Math.floor(columns / 3);
        const clusterHeightInSquares = Math.floor(rows / 3);
        const clusterXIndex = Math.floor(columns / 2);
        const clusterYIndex = Math.floor(rows / 2);
        const clusterXStart = Math.max(
            0,
            clusterXIndex - Math.floor(clusterWidthInSquares / 2)
        );
        const clusterXEnd = Math.min(
            columns - 1,
            clusterXIndex + Math.floor(clusterWidthInSquares / 2)
        );
        const clusterYStart = Math.max(
            0,
            clusterYIndex - Math.floor(clusterHeightInSquares / 2)
        );
        const clusterYEnd = Math.min(
            rows - 1,
            clusterYIndex + Math.floor(clusterHeightInSquares / 2)
        );
        const fragment = document.createDocumentFragment();
        const usedPositions = new Set();

        for (let i = 0; i < numSquaresToDisplay; i++) {
            const square = document.createElement("div");
            square.style.position = "absolute";
            square.style.width = `${squareSize}px`;
            square.style.height = `${squareSize}px`;

            const inCluster = Math.random() < pCluster;

            let xIndex, yIndex, positionKey;
            let maxAttempts = 1000;
            let attempts = 0;

            do {
                attempts++;

                if (inCluster) {
                    xIndex =
                        clusterXStart +
                        Math.floor(Math.random() * (clusterXEnd - clusterXStart + 1));
                    yIndex =
                        clusterYStart +
                        Math.floor(Math.random() * (clusterYEnd - clusterYStart + 1));
                } else {
                    xIndex = Math.floor(Math.random() * columns);
                    yIndex = Math.floor(Math.random() * rows);

                    const isInClusterX =
                        xIndex >= clusterXStart && xIndex <= clusterXEnd;
                    const isInClusterY =
                        yIndex >= clusterYStart && yIndex <= clusterYEnd;

                    if (isInClusterX && isInClusterY) {
                        continue;
                    }
                }

                positionKey = `${xIndex},${yIndex}`;
            } while (usedPositions.has(positionKey) && attempts < maxAttempts);

            if (attempts >= maxAttempts) {
                continue;
            }

            usedPositions.add(positionKey);

            const x = xIndex * squareSize;
            const y = yIndex * squareSize;

            square.style.left = `${x}px`;
            square.style.top = `${y}px`;

            const colorIndex = Math.floor(Math.random() * pixelColors.length);
            square.style.backgroundColor = pixelColors[colorIndex];

            fragment.appendChild(square);
        }

        overlay.appendChild(fragment);
        return overlay;
    }
};
