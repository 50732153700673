// app/javascript/animations/runAnimations.js
import { gsap } from "gsap";

export const runAnimations = () => {
    const animatedElements = document.querySelectorAll(".animate");

    animatedElements.forEach((element) => {
        gsap.from(element, {
            opacity: 0,
            y: 20,
            duration: 1,
            ease: "power2.out",
            delay: 0.2,
        });
    });
};
